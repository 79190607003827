.header{
    width: 100%;
    height: calc(50vh - 50px);
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url("../../assets/img/home_background.jpeg") no-repeat center;
    background-size: cover;
}

.headerTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-align: center;
}

.headerTitleSm{
    position: absolute;
    top: 30vh;
    font-size: 20px;
    margin-left: 15px;
    margin-right: 15px;
}

.headerTitleLg{
    position: absolute;
    top:15vh;
    font-size: 90px;
}
.headerImg{
    width: 100%;
    height: calc(50vh - 50px);
    object-fit: cover;
}
.headerCV{
    position: absolute;
    top:40vh;
    width: 200px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #17d1ac;
    color: white;
    border-radius: 5px;
    font-size: 1.5rem;
}

@media(max-width: 900px){
    .headerTitleSm{
        font-size: 14px;
        top: 30vh;
    }
    .headerTitleLg{
        top:20vh;
        font-size: 50px;
    }
}