.sidebar{
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    padding-bottom: 30px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.sidebarItem{
    display: flex;
    flex-direction: column;
    max-width: 250px;
    border-bottom: 1px solid #d3d3d399;
}

.sidebarTitle{
    margin: 15px 0;
    padding: 5px 0;
    width: 80%;
    color: #222;
    line-height: 20px;
    font-size: 24px;
    font-weight: 700;
    opacity: 0.7;
    text-transform: uppercase;
}

h2{
    margin-left: 0;
    padding-left: 0;
}
.sidebarItem > img{
    margin-top: 15px;
    width: 250px;
    max-width: 100%;
    height: 400px;
    border-radius: 10px;
    object-fit: cover;
    
}

.sidebarItem > p{
    padding: 5px 0;
    font-size: 14px;
}

.sidebarList{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 25px;
    padding: 0;
}

.sidebarListItem{
    margin-top: 15px;
    cursor: pointer;
}

.sidebarListItemSpan{
    font-size: 1rem;
    color: #be9656;
    line-height: 20px;
    margin-top: 15px;
    margin-right: 5px;
    cursor: pointer;
    border: 1px solid #be9656;
    border-radius: 20px;
    padding: 5px 10px;
}

.projectLink{
    margin-top: 20px;
    font-weight: 500;
    opacity: 0.7;
}

.sidebarListItem:hover{
    opacity: 0.5;
    transition: all ease 0.3s;
}
.sidebarSocial{
    margin-top: 15px;
    margin-bottom: 25px;
    width: 250px;
    display: flex;
}

.sidebarIcon{
    font-size: 32px;
    cursor: pointer;
    margin-right: 10px;
}

.sidebarIcon:hover{
    opacity: 0.5;
    color: #17d1ac;
    transition: all ease 0.3s;
}