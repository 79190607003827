.home{
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.container{
    padding: 0;
}
.posts{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
}
