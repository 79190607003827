.login{
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: 
        linear-gradient(rgba(255,255,255,0.5),rgba(255,255,255,0.5)),
        url("https://images.unsplash.com/photo-1432821596592-e2c18b78144f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmxvZ3xlbnwwfHwwfHw%3D&w=1000&q=80");
    background-size: cover;
}
.loginTitle{
    font-size: 50px;
}

.loginForm{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.loginForm>label{
    margin: 10px 0;
}

.loginInput{
    padding: 10px;
    background-color: white;
    border: none;

}

.loginButton{
    margin-top: 20px;
    cursor: pointer;
    background-color: lightcoral;
    border: none;
    color: white;
    border-radius: 10px;
}
.loginButton:disabled{
    cursor: not-allowed;
    opacity: 0.5;

}
.loginRegisterButton{
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: teal;
    cursor: pointer;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 10px;
}