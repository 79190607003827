.post{
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid #d3d3d399;
}

.postImg{
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
}

.postInfo{
    display: flex;
    flex-direction: column;
    line-height: 1.3;
    margin-top: 2rem;
}
.postCats{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}
.postCat{
    font-size: 1rem;
    color: #be9656;
    line-height: 20px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #be9656;
    border-radius: 20px;
    padding: 5px 10px;
}

.postCat:hover{
    opacity: 0.5;
}
.postTitle{
    font-size: 26px;
    font-weight: 700;
    cursor: pointer;
}

.postDate{
    font-style: italic;
    font-size: 13px;
    color: #999;
    margin-top: 15px;
    margin-bottom: 2rem;
}

.postDesc{
    font-size: 14px;
    color: #444;
    font-style: italic;
    line-height: 20px;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    max-width: 90%;
    word-wrap:break-word;

}

.postDesc:hover{
    opacity: 0.6;
    transition: all ease 0.3s;
}
