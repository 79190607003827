/* .singlePost{

} */
.singlePostWrapper{
    padding: 0;
    display: flex;
    flex-direction: column;
}

.container{
    padding: 15px;
}

.singlePostTitle{
    margin: 0;
    margin-top: 30px;
    color: #0a0a23;
    font-size: 5rem;
    font-weight: 700;
}

.singlePostTitleInput{
    margin: 10px;
    font-size: 28px;
    border: none;
    color: #666;
    border-bottom: 1px solid lightgrey;
}
.singlePostTitleInput:focus{
    outline: none;
}
.singlePostImg{
    width: 100%;
    height: auto;
    max-height: 60vh;
    border-radius: 5px;
    object-fit: cover;
}

.singlePostInfo{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #b39656;
}
.singlePostAuthor{
    font-size: 2rem;
}

.singlePostCats{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.singlePostCat{
    font-size: 1rem;
    color: #be9656;
    line-height: 20px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #be9656;
    border-radius: 20px;
    padding: 5px 10px;
}
.singlePostEdit{
    float: right;
    font-size: 16px;
}

.singlePostIcon{
    margin-left: 10px;
    cursor: pointer;
}

.singlePostIcon:first-child{
    color: teal;
}
.singlePostIcon:last-child{
    color: tomato;
}
.singlePostDesc{
    color: black;
    font-size: 2.75rem;
    line-height: 1.6em;
    max-width:100%;
    word-wrap:break-word;
    font-weight: bold;
    font-style: italic;
}
.singlePostDesc::first-letter{
    /* margin-left: 20px; */
    text-transform: uppercase;
}

.singlePostDescInput{
    border: none;
    color: #666;
    font-size: 18px;
    line-height: 25px;
}
.singlePostDescInput:focus{
    outline: none;
}


.singlePostContent{
    color: black;
    font-size: 2.2rem;
    line-height: 1.6em;

    max-width:100%;
    word-wrap:break-word;
}
.singlePostContent::first-letter{
    /* margin-left: 20px; */
    text-transform: uppercase;
}

.singlePostContentInput{
    border: none;
    color: #666;
    font-size: 18px;
    line-height: 25px;
    height: 60vh;
}
.singlePostContentInput:focus{
    outline: none;
}

.singlePostButton{
    width: 100px;
    border: none;
    background-color: teal;
    padding: 5px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 20px;
}

.pager .previous .link{
    font-size: 12px;
}

p img{
    width: 650px;
    object-fit:contain;
    max-width: 100%;
}

@media (max-width: 800px){
.singlePostContent {
    font-size: 1.9rem;
}}

@media (max-width: 500px){
.singlePostTitle {
    font-size: 2.9rem;
}}