.categoryPost{
    width: 100%;
    max-width: 100%;
    border-bottom: 1px solid #d3d3d399;
    padding-bottom: 30px;
}

.categoryPostImg{
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 7px;
}

.categoryPostInfo{
    display: flex;
    flex-direction: column;
    line-height: 1.3;
    margin-top: 2rem;
}
.categoryPostCats{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
}
.categoryPostCat{
    font-size: 1rem;
    color: #be9656;
    line-height: 20px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #be9656;
    border-radius: 20px;
    padding: 5px 10px;
}

.categoryPostCat:hover{
    opacity: 0.5;
}
.categoryPostTitle{
    font-size: 24px;
    font-weight: 700;

    cursor: pointer;
}

.categoryPostDate{
    font-style: italic;
    font-size: 13px;
    color: #999;
    margin-top: 15px;
    margin-bottom: 2rem;
}

.categoryPostDesc{
    font-size: 12px;
    color: #444;
    font-style: italic;
    line-height: 20px;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;

    max-width: 90%;
    word-wrap:break-word;

}

.categoryPostDesc:hover{
    opacity: 0.6;
    transition: all ease 0.3s;
}

@media(max-width: 900px){
    
    .categoryPostTitle{
        font-size: 2rem;
        font-weight: 600;
    }
}