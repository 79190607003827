/* .about{

} */
.aboutWrapper{
    padding: 0;
    display: flex;
    flex-direction: column;
}
.container{
    padding: 15px;
}
.aboutTitle{
    margin: 0;
    margin-top: 30px;
    color: #0a0a23;
    font-size: 5rem;
    font-weight: 700;
}

.aboutTitleInput{
    margin: 10px;
    font-size: 28px;
    border: none;
    color: #666;
    border-bottom: 1px solid lightgrey;
}
.aboutTitleInput:focus{
    outline: none;
}
.aboutImg{
    width: 100%;
    height: auto;
    max-height: 60vh;
    border-radius: 5px;
    object-fit: contain;
    margin: 20px 0;
}

.aboutInfo{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #b39656;
}
.aboutAuthor{
    font-size: 2rem;
}

.aboutCats{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.aboutCat{
    font-size: 1rem;
    color: #be9656;
    line-height: 20px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #be9656;
    border-radius: 20px;
    padding: 5px 10px;
}
.aboutEdit{
    float: right;
    font-size: 16px;
}

.aboutIcon{
    margin-left: 10px;
    cursor: pointer;
}

.aboutIcon:first-child{
    color: teal;
}
.aboutIcon:last-child{
    color: tomato;
}
.aboutDesc{
    color: black;
    font-size: 2.75rem;
    line-height: 1.6em;
    max-width:100%;
    word-wrap:break-word;
    font-weight: bold;
    font-style: italic;
}
.aboutSubtitle{

    color: black;
    font-size: 2.75rem;
    line-height: 1.6em;
    max-width:100%;
    word-wrap:break-word;
}
.aboutDesc::first-letter{
    /* margin-left: 20px; */
    text-transform: uppercase;
}

.aboutDescInput{
    border: none;
    color: #666;
    font-size: 18px;
    line-height: 25px;
}
.aboutDescInput:focus{
    outline: none;
}


.aboutContent{
    color: black;
    font-size: 2.2rem;
    line-height: 1.6em;

    max-width:100%;
    word-wrap:break-word;
}
.aboutContent::first-letter{
    /* margin-left: 20px; */
    text-transform: uppercase;
}

.aboutContentInput{
    border: none;
    color: #666;
    font-size: 18px;
    line-height: 25px;
    height: 60vh;
}
.aboutContentInput:focus{
    outline: none;
}

.aboutButton{
    width: 100px;
    border: none;
    background-color: teal;
    padding: 5px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 20px;
}

.pager .previous .link{
    font-size: 12px;
}

p{
    margin: 20px 0;
}

@media (max-width: 800px){
.aboutContent {
    font-size: 1.9rem;
}}

@media (max-width: 500px){
.aboutTitle {
    font-size: 2.9rem;
}}