/* .portfolio{

} */

.portfolioWrapper{
    padding: 0;
    display: flex;
    flex-direction: column;
}
.container{
    padding: 15px;
}

.portfolioTitle{
    margin: 0;
    margin-top: 30px;
    color: #0a0a23;
    font-size: 5rem;
    font-weight: 700;
}

.portfolioTitleInput{
    margin: 10px;
    font-size: 28px;
    border: none;
    color: #666;
    border-bottom: 1px solid lightgrey;
}
.portfolioTitleInput:focus{
    outline: none;
}
.portfolioImg{
    width: 100%;
    height: auto;
    max-height: 60vh;
    border-radius: 5px;
    object-fit: contain;
    margin: 20px 0;
}

.portfolioInfo{
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #b39656;
}
.portfolioAuthor{
    font-size: 2rem;
}

.portfolioCats{
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}
.portfolioCat{
    font-size: 1rem;
    color: #be9656;
    line-height: 20px;
    margin-right: 10px;
    cursor: pointer;
    border: 1px solid #be9656;
    border-radius: 20px;
    padding: 5px 10px;
}
.portfolioEdit{
    float: right;
    font-size: 16px;
}

.portfolioIcon{
    margin-left: 10px;
    cursor: pointer;
}

.portfolioIcon:first-child{
    color: teal;
}
.portfolioIcon:last-child{
    color: tomato;
}
.portfolioDesc{
    color: black;
    font-size: 2.75rem;
    line-height: 1.6em;
    max-width:100%;
    word-wrap:break-word;
    font-weight: bold;
    font-style: italic;
}
.portfolioDesc::first-letter{
    /* margin-left: 20px; */
    text-transform: uppercase;
}

.portfolioDescInput{
    border: none;
    color: #666;
    font-size: 18px;
    line-height: 25px;
}
.portfolioDescInput:focus{
    outline: none;
}


.portfolioContent{
    color: black;
    font-size: 2.2rem;
    line-height: 1.6em;

    max-width:100%;
    word-wrap:break-word;
}
.portfolioContent::first-letter{
    /* margin-left: 20px; */
    text-transform: uppercase;
}

.portfolioContentInput{
    border: none;
    color: #666;
    font-size: 18px;
    line-height: 25px;
    height: 60vh;
}
.portfolioContentInput:focus{
    outline: none;
}

.portfolioButton{
    width: 100px;
    border: none;
    background-color: teal;
    padding: 5px;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    align-self: flex-end;
    margin-top: 20px;
}

.pager .previous .link{
    font-size: 12px;
}

p{
    margin: 20px 0;
}

img{
    width: 100%;
    max-height: 60vh;
    object-fit: contain;
}
h1{
    font-weight: 700;
    font-size: 2em;
}
h2{
    font-weight: 600;
    font-size: 1.25em;
}
@media (max-width: 800px){
.portfolioContent {
    font-size: 1.9rem;
}}

@media (max-width: 500px){
.portfolioTitle {
    font-size: 2.9rem;
}}